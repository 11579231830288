/* src/components/ServicePage.css */
.service-page {
  margin: 3rem 0;
  text-align: center;
}

.service-pagee{
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 1.3rem 0;
  /* height: 50dvh; */
}

.service-pagees{
  height: 100dvh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  /* height: 50dvh; */
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

/* .service {
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd;
  margin: 10px;
  flex: 1;
} */
.service {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 23%; /* Display 3 services per row on desktop */
  /* margin-right: 3%;
  margin-bottom: 3%; */
  /* float: left; */
  margin: 21px;
  gap: 1.5rem;
  padding-bottom: 11px;
  border-radius: 3px;
  /* border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  border-top-right-radius: 50px; */
  /* border-radius: 11px; */
  /* padding: 21px 0; */
  /* justify-content: center; */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.service:hover img {
  /* background: pink; */
  /* width: 21.1%;
  padding: 11px 0;
  margin: 21px; */
  /* color: #ff8838; */
  filter: grayscale(100%);
  box-shadow: 2px 2px 5px rgba(39, 55, 100, 0.849);
}
.service:hover p{
  color: #ff8838;
}
.service img {
  display: block;
  max-width: 100%;
  /* border-top-right-radius: 50px; */
  /* border-radius: 50%; */
  border-radius: 0 50px 50% 50%;
  /* margin: 15px; */
  border: 5px solid #ff8838c2;
  /* height: auto; */
}
.service img:hover{
  filter: grayscale(100%);
}
/* !! help button */
.btn-helpp{
  position: absolute;
  left: 15px;
  top: 10px;
  width: 30px;
  opacity: 0.5;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.7s ease-in-out;
}

.btn-helpp:hover{
  background-color: #eee;
  border-radius: 15px;
  width: 300px;
  height: 350px;
  text-align: start;
  opacity: 1;

}
.btn-helpp svg{
  position: absolute;
}

.btn-helpp:hover svg{
  opacity: 0;
}

.btn-helpp svg{
  font-size: 30px;
}

.text-section2{
  padding: 20px;
  opacity: 0;

}
.btn-helpp:hover .text-section2{
  animation: forwards fadeIn 0.7s 0.5s ease-in-out;

}

@keyframes fadeIn{
  0%{
    opacity: 0;

  }
  100%{
    opacity: 1;
  }
}

.overlayy {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ff8838;
  overflow: hidden;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  width: 100%;
  height: 0;
  transition: .5s ease;
}
.service:hover .overlayy {
  height: 31%;
}
.desscc {
  white-space: nowrap; 
  color: white;
  font-size: 19px;
  font-weight: 500;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}


/* Menyusun ulang tata letak untuk tampilan mobile */
@media (max-width: 767px) {
  .services {
    /* flex-direction: column; */
    align-items: center;
  }

  .service {
    width: 39%; /* Display 2 services per row on mobile */
    margin: 5px;
  }
  .desscc{
    white-space: initial;
  }
  .service:hover .overlayy {
    height: 45%;
  }
}

/* Menyusun ulang tata letak untuk tampilan tablet */
@media (min-width: 768px) and (max-width: 991px) {
  .services {
    /* justify-content: center; */
    /* justify-content: space-between; */
  }

  .service {
    width: 23%; /* Menyusutkan lebar untuk menampilkan 2 per baris */
  }

  /* Menambahkan kelas baru untuk menampilkan 3 per baris */
  .services.three-per-row {
    /* justify-content: space-around; */
  }
  .desscc{
    white-space: unset;
  }
  .service:hover .overlayy {
    height: 45%;
  }
  /* Mengubah lebar service untuk menampilkan 3 per baris */
  .services.three-per-row .service {
    width: 33%;
  }
}
