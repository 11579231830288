.container-splash{
    display: grid;
    place-items: center;
}

.loaderrr {
    position: relative;
    width: 2.5em;
    height: 2.5em;
    transform: rotate(165deg);
   }
   
   .loaderrr:before, .loaderrr:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0.5em;
    height: 0.5em;
    border-radius: 0.25em;
    transform: translate(-50%, -50%);
   }
   
   .loaderrr:before {
    animation: before8 2s infinite;
   }
   
   .loaderrr:after {
    animation: after6 2s infinite;
   }
   
   @keyframes before8 {
    0% {
     width: 0.5em;
     box-shadow: 1em -0.5em rgba(255, 146, 9, 1), -1em 0.5em rgba(43, 52, 153, 1);
    }
   
    35% {
     width: 3.1em;
     box-shadow: 0 -0.5em rgba(255, 146, 9, 1), 0 0.5em rgba(43, 52, 153, 1);
    }
   
    70% {
     width: 0.5em;
     box-shadow: -1em -0.5em rgba(255, 146, 9, 1), 1em 0.5em rgba(43, 52, 153, 1);
    }
   
    100% {
     box-shadow: 1em -0.5em rgba(255, 146, 9, 1), -1em 0.5em rgba(43, 52, 153, 1);
    }
   }
   
   @keyframes after6 {
    0% {
     height: 0.5em;
     box-shadow: 0.5em 1em rgba(255, 208, 153, 1), -0.5em -1em rgba(255, 108, 34, 1);
    }
   
    35% {
     height: 3.1em;
     box-shadow: 0.5em 0 rgba(255, 208, 153, 1), -0.5em 0 rgba(255, 108, 34, 1);
    }
   
    70% {
     height: 0.5em;
     box-shadow: 0.5em -1em rgba(255, 208, 153, 1), -0.5em 1em rgba(255, 108, 34, 1);
    }
   
    100% {
     box-shadow: 0.5em 1em rgba(255, 208, 153, 1), -0.5em -1em rgba(255, 108, 34, 1);
    }
   }
   
   .loader {
    position: absolute;
    top: calc(50% - 1.25em);
    left: calc(50% - 1.25em);
   }