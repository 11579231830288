/* src/components/ServicePage.css */
.service-page {
    min-height: 100vh;
    margin: 3rem 0;
    text-align: center;
}

.services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

/* .service {
    text-align: center;
    padding: 20px;
    border: 1px solid #ddd;
    margin: 10px;
    flex: 1;
  } */
.newscard {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 23%;
    /* Display 3 services per row on desktop */
    /* margin-right: 3%;
    margin-bottom: 3%; */
    /* float: left; */
    margin: 21px;
    gap: 1.5rem;
    /* padding-bottom: 11px; */
    border-radius: 3px;
    overflow: hidden;
    /* border-top-right-radius: 50px; */
    /* border-radius: 11px; */
    /* padding: 21px 0; */
    /* justify-content: center; */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.newss p {
    margin: 0 5px;
}

.newscard:hover img {
    /* background: pink; */
    /* width: 21.1%;
    padding: 11px 0;
    margin: 21px; */
    /* color: #ff8838; */
    /* transition: transform .5s ease; */
    filter: grayscale(100%);
    transform: scale(1.1);
    box-shadow: 2px 2px 5px rgba(39, 55, 100, 0.849);
}

.newscard img{
    transition: transform .5s ease;
}

/* .newscard:hover p {
    color: #ff8838;
} */

.newscard img {
    display: block;
    max-width: 100%;
    border-radius: 11px 11px 0 0;
    /* border-top-right-radius: 50px; */
    /* border-radius: 50%; */
    /* border-radius: 0 50px 50% 50%; */
    /* margin: 15px; */
    /* border: 5px solid var(--palett3); */
    /* height: auto; */
}

/* .newscard img:hover {
    filter: grayscale(100%);
} */

.overlayy {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ff8838;
    overflow: hidden;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    width: 100%;
    height: 0;
    transition: .5s ease;
}

.service:hover .overlayy {
    height: 31%;
}

.desscc {
    white-space: nowrap;
    color: white;
    font-size: 19px;
    font-weight: 500;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.descmeta {
    /* max-height: 90px; */
    /* overflow: hidden; */
    max-height: 150px;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: break-word;
    text-overflow: ellipsis;
}

/* .typing{
    position: relative;
}
.typing::after {
    content: "|";
    position: absolute;
    right: 0;
    width: 100%;
    animation: typing 4s steps(16) forwards, caret 1s infinite;
}

@keyframes typing {
    to { width: 0;}
}

@keyframes caret {
    50% { color: transparent;}
} */

/* Menyusun ulang tata letak untuk tampilan mobile */
@media (max-width: 767px) {
    .services {
        /* flex-direction: column; */
        align-items: center;
    }

    .service {
        width: 39%;
        /* Display 2 services per row on mobile */
        margin: 5px;
    }

    .desscc {
        white-space: initial;
    }

    .service:hover .overlayy {
        height: 45%;
    }
    .kontakcont{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* Menyusun ulang tata letak untuk tampilan tablet */
@media (min-width: 768px) and (max-width: 991px) {
    

    .service {
        width: 23%;
        /* Menyusutkan lebar untuk menampilkan 2 per baris */
    }

    .desscc {
        white-space: unset;
    }

    .service:hover .overlayy {
        height: 45%;
    }

    /* Mengubah lebar service untuk menampilkan 3 per baris */
    .services.three-per-row .service {
        width: 33%;
    }
}