.berutama{
    display: grid;
    place-items: center;
    width: 100%;
    height: 100vh;
    background-color: red;
}

.outteerrmain{
    width: 90%;
    flex-wrap: wrap;
    min-height: 80%;
    background-color: pink;
}

.outerrleft{
    width: 70%;
    background-color: yellow;
}

.outerrright{
    display: flex;
    align-items: center;
    width: 30%;
    gap: 1rem;
    justify-content: center;
    flex-direction: column;
    /* width: 50%; */
    /* height: 90%; */
    background-color: greenyellow;
}

.outerrright img{
    width: 250px;
    height: auto;
}

/* tablet view */
@media (min-width: 601px) {
    
  }

  /* Desktop view */
  @media (min-width: 1024px) {
    
  }

  /* mobile view */
  @media (max-width: 600px){
    .outteerrmain{
        flex-direction: column;
        align-items: center;
    }
  }