.tabscard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabscard-nav {
  display: flex;
  margin-bottom: 20px;
}

.tabscard {
  cursor: pointer;
  padding: 10px 0;
  margin-right: 15px;
  position: relative;
}

.tabscard::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--palett3);
  transition: width 0.3s ease-in-out;
}

.tabscard:hover::before,
.tabscard.active::before {
  width: 100%;
}

.tabscard:last-child {
  margin-right: 0;
}

.tabscard-content {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 0 0 5px 5px;
}
