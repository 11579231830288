/* src/components/TeamPage.css */
.team-page {
    text-align: center;
    justify-content: space-evenly;
    /* margin-top: 3rem; */
    display: flex;
    flex-direction: column;
    min-height: 150dvh;
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    /* height: 90%; */
    /* background-color: red; */
}
.h1team{
    font-size: 32px;
    font-weight: bold;
    /* position: relative; */
  }
  
  .h1team::after {
    content: " Team";
    color: var(--palett3);
    position: relative;
    /* top: 0;
    left: 100%; */
  }
.team-member {
    display: flex;
    flex-direction: column;
    width: 30%;
    border-radius: 5px;
    margin: 3px 9px;
    gap: 0.5em;
    text-align: center;
    padding: 21px 0 34px 0;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    /* margin: 0 20px 40px; */
}

.team-member:hover {
    /* box-shadow: 0 2px 17px rgba(39, 55, 100, 0.849); */
}

.team-member img {
    /* max-width: 100%; */
    width: 190px;
    height: 185px;
    object-fit: cover;
    transition: transform 0.3s ease;
    border-radius: 50%;
    /* border: 3px solid blue; */
}

.team-member img:hover {
    transform: rotateZ(-21deg);
}

.team-member h2 {
    font-size: 1.5rem;
    margin: 10px 0;
}

.team-member p {
    font-size: 1rem;
    font-weight: 500;
    color: #ff8838;
}

.social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 8px; */
    /* background-color: pink; */
    gap: 0.5rem;
}

.social-links a {
    font-size: 18px;
    padding: 5px 9px;
    border-radius: 10px;
    /* background-color: #2980b9; */
    /* margin-right: 8px; */
    color: #3498db;
    /* Ganti warna sesuai kebutuhan */
}

.social-links a:hover {
    color: orange;
    /* Warna saat hover */
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
  
  .tab-button {
    cursor: pointer;
    padding: 8px 16px;
    margin: 0 8px;
    border: none;
    background: none;
    /* font-size: 36px; */
    color: #555;
  }
  
  .tab-button.active {
    /* color: #007bff;  */
    border-bottom: 2px solid orange; /* Garis bawah pada tab aktif */
  }

/* Menyusun ulang tata letak untuk tampilan mobile */
@media (max-width: 767px) {
    .team-members {
        /* flex-direction: column; */
        /* align-items: center; */
    }

    .team-member {
        border-radius: 9px;
        width: 47%;
        padding: 17px 13px;
        margin: 0;
        
    }
    .team-member img {
        /* max-width: 100%; */
        width: 140px;
        height: 140px;
        object-fit: cover;
        transition: transform 0.3s ease;
        border-radius: 50%;
        /* border: 3px solid blue; */
    }
    .team-member p {
        font-size: 14px;
        /* font-size: 1rem; */
        font-weight: medium;
        color: #ff8838;
    }
}

/* Menyusun ulang tata letak untuk tampilan tablet */
@media (min-width: 768px) and (max-width: 991px) {
    .team-members {
        /* align-items: center; */
        /* justify-content: space-between; */
    }

    .team-member {
        padding: 17px 13px;
        /* margin: 9px 11px; */
        width: 29%;
        /* Menyusutkan lebar untuk menampilkan 2 per baris pada tablet */
    }
}