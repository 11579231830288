.solusi-page {
    /* margin: 3rem 3px; */
    text-align: start;
    /* background-color: pink; */
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    justify-content: center;
    /* min-width: hei; */
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.438), rgba(0, 0, 0, 0.432)),url("../../images/prime/proknow3.webp"); */
    /* background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
}

.h1testiii {
    font-weight: bold;
    font-size: 32px;
}

.h1testiii::after {
    content: " Do?";
    color: var(--palett2);
    position: relative;
    /* top: 0;
    left: 100%; */
}

.h3testiii {
    font-size: 19px;
    font-weight: 600;
    color: var(--palett3);
}

.solusi-page p {
    align-self: flex-start;
    /* margin-left: 1rem; */
}

.solusis {
    display: flex;
    /* gap: 2.5rem; */
    width: 90%;
    /* justify-content: space-between; */
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    row-gap: inherit;
    /* background-color: red; */

}

.solusi {
    /* background-color: #fff; */
    /* background: transparent; */
    /* backdrop-filter: blur(11px); */
    /* border: 1px solid rgba(128, 128, 128, 0.541); */
    width: 30%;
    /* padding: 15px 0; */
    height: 240px;
    /* border-radius: 1px; */
    align-items: center;
    justify-content: center;
    display: flex;
    /* box-shadow: 0 3px 3px gray; */
    /* border-radius: 0px; */
    background-color: #ebeaea;
    
}

.solshadw{
    box-shadow: 20px 20px 60px #cacaca,
        -20px -20px 60px #ffffff;
}

.batass {
    height: 10px;
    border-radius: 5px;
    width: 90px;
    background-color: var(--palett1);
}

/* .solusi:hover {
    backdrop-filter: blur(21px);
} */

/* 
.solusi:nth-of-type(2){
    margin-top: 1.5rem;
} */
.middd {
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    /* justify-content: center; */
    /* background-color: yellow; */
    /* justify-content: space-between; */
}

.middd h2 {
    margin: 0.3rem 0;
    font-size: 21px;
    font-weight: 600;
    color: var(--palett3);
}

.middd p {
    /* color: var(--palet); */
    font-weight: 300;
    font-size: medium;
}

.middd img {
    max-width: 30%;
}

.lgimgsol {
    background-color: var(--palett1);
    padding: 5px;
    border-radius: 5px;
    color: var(--palett3);
}

.animatetg {
    position: relative;
    font-size: 5rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 80px;
    cursor: pointer;
}

.tekss {
    color: transparent;
    -webkit-text-stroke: 1px rgb(255, 255, 255, 0.6);

}

.tekss2 {
    position: absolute;
    inset: 0;
    width: 0%;
    color: var(--palett1);
    overflow: hidden;
    border-right: 6px solid var(--palett3);
    transition: 0.5s ease-in-out;
}

.animatetg:hover .tekss2 {
    width: 100%;
    filter: drop-shadow(0 0 40px green);
}

/* todo !! Mobile Mode */

@media (max-width: 767px) {
    .solusi-page {
        width: 100%;
        height: 120dvh;
        /* background-color: red; */
        justify-content: space-evenly;
        align-items: center;
        gap: 0;
    }

    .solusis {
        /* flex-direction: column; */
        align-items: center;
        justify-content: none;
        width: 95%;
        height: 100%;
        /* gap: 1rem; */
        /* background-color: yellow; */
    }

    .solusi {
        width: 90%;
        height: 210px;
        padding: 10px 0;
        /* justify-content: none; */
    }

}


/* todo  tablet mode */
@media (min-width: 768px) and (max-width: 991px) {}