.conDet {
    /* background-color: pink; */
    /* margin: 5px 0; */
    min-height: 150vh;
    /* padding: 5px 1rem; */
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    /* gap: 5px; */
}

.sideleftblogdet {
    display: flex;
    flex-direction: column;
    /* background-color: yellow; */
    width: 70%;
    /* text-align: center; */
    align-items: center;
}

.txtjudul {
    width: 80%;
    text-align: center;
    /* background-color: yellowgreen; */
}

.penuliss {
    gap: 0.5rem;
    align-items: center;
    /* justify-content: center; */
    /* background-color: red; */
}

.penuliss p {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.sideleftblogdet > p {
    font-size: 11px;
    color: gray;
}
.imgndetail{
    width: 80%;
    flex-direction: column;
    /* align-items: center; */
    /* text-align: start; */
    /* background-color: purple; */
}
.imgndetail img{
    max-width: 100%;
    height: auto;
    /* align-self: center; */
    object-fit: cover;
}
/* .imgndetail p{
    align-self: flex-start;
} */
.sidertblogdet{
    /* background-color: red; */
    display: flex;
    width: 25%;
    align-items: center;
    /* width: 20%; */
    height: auto;
}
.adsprime{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    /* background-color: red; */
    /* width: 100%; */
}
.adsprime img{
    max-width: 80%;
    height: auto;
}

/* !! isi artikell */
/* todo */

.isiartkel{
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    width: 80%;
    /* gap: 3em; */
}
article{
    display: flex;
    flex-direction: column;
    text-wrap: balance;
    /* gap: 3em; */
}
