*{
  --paletcard1: #2B3499;
  --paletcard2: #FF6C22;
  --paletcard3: #FF9209;
  --paletcard4: #FFD099;

}
.h1serv{
  font-size: 32px;
  font-weight: bold;
  /* position: relative; */
}

.h1serv::after {
  content: " Do";
  color: var(--palett1);
  position: relative;
  /* top: 0;
  left: 100%; */
}
.outer {
    /* height: 100vh; */
    width: 100%;
    display: grid;
    place-items: center;
  }
.outers {
    /* height: 100vh; */
    width: 100%;
    display: grid;
    place-items: center;
    min-height: 100dvh;
    padding-top: 7rem;
  }
  .outercontak{
    width: 100%;
    padding-top: 7rem;
    height: 100dvh;
    display: grid;
    place-items: center;
  }
  .row-service {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 80%;
    /* gap: 5px; */
  }
  
  .column-service {
    width: 100%;
    padding: 0 1em 1em 1em;
    text-align: center;
  }

  .icon-wrapper-card2{
    display: none;
  }

  .card-service {
    min-width: 250px;
    width: 350;
    min-height: 250px;
    height: 350px;
    padding: 2em 1.5em;
    background: linear-gradient(#ffffff 50%, var(--paletcard3) 50%);
    background-size: 100% 200%;
    background-position: 0 2.5%;
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
  }
  
  .card-service h3 {
    font-size: 20px;
    font-weight: 600;
    color: var(--paletcard2);
    margin: 1em 0;
  }
  
  .card-service p {
    color: #575a7b;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
  }
  
  .icon-wrapper-card {
    background-color: #2B3499;
    position: relative;
    margin: auto;
    font-size: 30px;
    height: 2.5em;
    width: 2.5em;
    color: #fff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
  }
  
  .card-service:hover {
    background-position: 0 100%;
  }

  .card-service:hover .icon-wrapper-card {
    background-color: #ffffff;
    color: var(--paletcard2);
  }
  
  .card-service:hover h3 {
    color: var(--paletcard1);
  }
  
  .card-service:hover p {
    color: #f0f0f0;
  }
  
  @media screen and (min-width: 768px) {
    .outer {
      padding: 0 2em;
    }
  
    .column-service {
      flex: 0 50%;
      max-width: 50%;
    }
  }
  
  @media screen and (min-width: 992px) {
    .outer {
      padding: 1em 3em;
    }
  
    .column-service {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }
  

