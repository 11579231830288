.herodpn {
    width: 100%;
    display: grid;
    height: 100dvh;
    place-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.438), rgba(0, 0, 0, 0.432)),url('../../images/team/resize/bghero.webp');
    /* background-image: url('../../images/background/backherocolor.webp'); */
    background-position: center;
    background-repeat: no-repeat;
    /* background: linear-gradient(
        rgba(0, 0, 0, 0.247),
        rgba(0, 0, 0, 0)
    ); */
    background-size: cover;
}



.landasann {
    width: 95%;
    height: 90%;
    /* background-color: yellowgreen; */
    /* align-items: center; */
    /* justify-content: center; */
}

.herooleft {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* background-color: rebeccapurple; */
}

.herooright {
    display: grid;
    place-items: center;
    width: 40%;
    /* background-color: darkblue; */
}

#mainname{
    color: #fff;
    font-size: 64px;
    font-weight: bold;
}
#subname{
    color: #fff;
    font-size: 35px;
    font-weight: 500;
}

/* !! baris pertama */
.baristama {
    /* width: 39%; */
    justify-content: space-between;
    /* background-color: yellow; */
    border: 1.5px solid rgba(255, 251, 251, 0.863);
    border-radius: 3px;
    color: #fff;
}
/* !! animation style */
.bounce-top {
	-webkit-animation: bounce-top 0.9s both;
	        animation: bounce-top 0.9s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-1-27 8:39:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
              transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
              transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
              transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
              transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  @keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
              transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
              transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
              transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
              transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  


.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 0.9s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 0.9s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2024-1-27 8:34:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  
  

/* !! end of button style */


.baristama button {
    background-color: var(--palett2);
    margin: 0 3px;
    color: white;
    padding: 3px 9px;
    border-radius: 3px;
}

.baristama h3 {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.descdetail {
    width: 50%;
    /* background-color: yellow; */
}

.descdetail h4 {
    color: white;
    font-weight: 500;
    text-align: center;
    text-wrap: balance;
}

/* !! style button */

.button-2 {
    /* width: 150px; */
    text-align: center;
    justify-content: space-evenly;
    padding: 11px 15px;
    color: #fff;
    /* border-radius: 5px; */
    /* border: 3px solid rgba(128, 128, 128, 0.479); */
    background-image: -webkit-linear-gradient(30deg, var(--palett3) 50%, transparent 50%);
    background-image: linear-gradient(30deg, var(--palett2) 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    text-transform: uppercase;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button-2:hover {
    background-position: 100%;
    background-color: var(--palett1);
    /* color: var(--palett3); */
}

.button-2 h6 {
    margin: 0;
    font-weight: 700;
}

.button-2 svg {
    width: 20px;
    /* background-color: #000; */
    margin: 0;
    padding: 0;
}

.click-btn {
    display: flex;
    width: 150px;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
    line-height: 35px;
    border: 1px solid;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    color: #000;
    text-decoration: none;
    transition: all 0.35s;
    box-sizing: border-box;
}

/* !! video style */

.image-container {
    position: relative;
    /* display: inline-block; */
    display: grid;
    place-items: center;
    /* place-items: center; */
    /* background-color: red; */
}


.play-button {
    display: grid;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    place-items: center;
    background-color: var(--palett2);
    transform: translate(-50%, -50%);
    /* background-color: transparent; */
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.herobbb{
    z-index: 0;
}

.video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: rgba(0, 0, 0, 0.521);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.video-modal iframe {
    width: 80%;
    height: 80%;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
}
/* !! animated for text h1 primetime */
.focus-in-contract-bck {
	-webkit-animation: focus-in-contract-bck 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focus-in-contract-bck 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-1-17 13:38:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-contract-bck-top
 * ----------------------------------------
 */
 /* ----------------------------------------------
 * Generated by Animista on 2024-1-17 13:56:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation focus-in-contract-bck
 * ----------------------------------------
 */
@-webkit-keyframes focus-in-contract-bck {
    0% {
      letter-spacing: 1em;
      -webkit-transform: translateZ(300px);
              transform: translateZ(300px);
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(12px);
              transform: translateZ(12px);
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes focus-in-contract-bck {
    0% {
      letter-spacing: 1em;
      -webkit-transform: translateZ(300px);
              transform: translateZ(300px);
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(12px);
              transform: translateZ(12px);
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  



/* !! animated bg */
.animated-bg {
    position: absolute;
    content: "";
    /* left: 140px; */
    top: 0;
    width: 450px;
    height: 390px;
    background: var(--palett4);
    border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
    will-change: border-radius, transform, opacity;
    animation: bganimeUltimate 5s linear infinite;
    /* display: inline-block; */
    z-index: 0;
    -webkit-animation: bganimeUltimate 5s linear infinite;
}
@-webkit-keyframes bganimeUltimate{
  0%,100%{
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    -webkit-transform: translate3d(0,0,0) rotateZ(0.01deg);
            transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  34%{
      border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    -webkit-transform:  translate3d(0,5px,0) rotateZ(0.01deg);
            transform:  translate3d(0,5px,0) rotateZ(0.01deg);
  }
  50%{
    -webkit-transform: translate3d(0,0,0) rotateZ(0.01deg);
            transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  67%{
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60% ;
    -webkit-transform: translate3d(0,-3px,0) rotateZ(0.01deg);
            transform: translate3d(0,-3px,0) rotateZ(0.01deg);
  }
}

@keyframes bganimeUltimate{
  0%,100%{
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    -webkit-transform: translate3d(0,0,0) rotateZ(0.01deg);
            transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  34%{
      border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    -webkit-transform:  translate3d(0,5px,0) rotateZ(0.01deg);
            transform:  translate3d(0,5px,0) rotateZ(0.01deg);
  }
  50%{
    -webkit-transform: translate3d(0,0,0) rotateZ(0.01deg);
            transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  67%{
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60% ;
    -webkit-transform: translate3d(0,-3px,0) rotateZ(0.01deg);
            transform: translate3d(0,-3px,0) rotateZ(0.01deg);
  }
}

/* !! responsive style */

/* todo !! Mobile Mode */

@media (max-width: 767px) {
    .landasann {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        z-index: 20;
        gap: 5rem;
        /* text-align: center;
        justify-content: center; */
    }

    .herooleft {
        width: 100%;
        align-items: center;
        /* justify-content: end; */
        padding-top: 5px;
        gap: 0.5rem;
        height: 80%;
        /* background-color: pink; */
    }

    .herooright {
        width: 100%;
        align-items: center;
    }
    .image-container img {
        max-width: 50%;
        z-index: 10;
    }
    .descdetail h4 {
        text-align: center;
        font-size: 14px;
        line-height: normal;
    }

    /* !! left side baris pertama */
    .baristama{
        width: 75%;
        justify-content: space-evenly;
        font-size: 9px;
        border: 1.5px solid rgba(128, 128, 128, 0.541);
    }

    /* !! button */
    .button-2{
        width: 110px;
        font-size: 11px;
    }

    
}


/* todo  tablet mode */
@media (min-width: 768px) and (max-width: 991px) {}