.outerrrbrandss{
    display: grid;
    place-items: center;
    width: 100%;
    height: 100dvh;
}

.outerrmainbrands{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 90%;
}

.h1clients{
    font-size: 32px;
    font-weight: bold;
    /* position: relative; */
  }
  
  .h1clients::after {
    content: " Clients";
    color: var(--palett3);
    position: relative;
    /* top: 0;
    left: 100%; */
  }