/* kontainer utama */
.benefcont{
    display: grid;
    height: 100vh;
    place-items: center;
    background-color: #fcf8f8;
}
.contbenf {
    display: flex;
    align-items: center;
    /* background-color: orange; */
    /* height: 90vh; */
}

/* sisi kiri */
.benfside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    /* height: 90vh; */
    /* background-color: purple; */
    /* gap: 1rem; */
}

.sideimg {
    display: flex;
    width: 90%;
    height: 50vh;
    align-items: end;
    justify-content: center;
    /* background-color: yellow; */
    gap: 13px;
    padding-bottom: 5px;


}
.sideimg2 {
    display: flex;
    width: 90%;
    height: 50vh;
    align-items: start;
    justify-content: center;
    /* align-self: center; */
    /* background-color: green; */
    gap: 13px;
    padding-top: 5px;
    /* flex-direction: column; */

}
.imgbt1{
    /* display: flex; */
    /* object-fit: cover; */
    border-radius: 1px;
    max-width: 150px;
    z-index: 11;
    /* max-height: auto; */
}
.imgbtt2{
    border-radius: 1px;
    max-width: 190px;
    z-index: 11;
    /* object-fit: cover; */
    /* max-height: fit-content; */
}
.imgbt3{
    border-radius: 1px;
    max-width: 130px;
}
.imgbt4{
    border-radius: 1px;
    max-width: 170px;
    max-height: 210px;
    object-fit: cover;
}


/* sisi kanan */
.righttsideer {
    display: flex;
    flex-direction: column;
    /* background-color: greenyellow; */
    align-items: self-start;
    justify-content: center;
    width: 50%;
    height: 80vh;
    margin-right: 1rem;
}

.sideRitem {
    display: flex;
    flex-direction: column;
    /* background-color: pink; */
    /* margin-left: 11px; */
}

.sideRitem h1{
    font-weight: 400;
}
.sideRitem p{
    color: gray;
}

.sideRitem2{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* align-items: center; */
    /* justify-content: space-between; */
    /* height: 10vh; */
    gap: 1rem;
    /* background-color: burlywood; */
}
.benfitemmm1 {
    display: flex;
    justify-content: space-between;
    width: 80%;
    /* background-color: aqua; */
    gap: 3rem;
}
.kotakexx{
    width: 50%;
    height: 40px;
    /* background-color: chocolate; */
}



@media only screen and (max-width: 600px) {
    .contbenf{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 11px;
        gap: 1.5rem;
    }
    .benfside{
        width: 100%;
        height: auto;
        /* background-color: pink; */
    }
    .righttsideer{
        width: 90%;
        height: auto;
        /* background-color: yellow; */
        /* margin-right: 0; */
        /* padding: 0 9px; */
        margin-right: 0;
    }

    .sideimg{
        height: auto;
    }
    .sideimg2{
        height: auto;
    }
    .righttsideer{
        height: auto;
    }

    .imgbt1{
        max-width: 90px;
        
    }
    .imgbtt2{
        max-width: 120px;

    }
    .imgbt3{
        
        max-width: 90px;
    }
    .imgbt4{
        
        max-width: 100px;
        
    }

    .sideRitem{
        text-align: center;
    }
    .sideRitem h1{
        font-size: large;
    }

    .sideRitem h2{
        text-wrap: balance;
        font-size: large;
    }
    .sideRitem p{
        font-size: small;
    }

    .kotakexx p{
        /* text-align: center; */
        font-size: small;
    }
    .benfitemmm1{
        gap: 0;
        width: 100%;
    }


  }
  
  /* Gaya untuk layar antara 601px dan 1024px (Tablet) */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    
  
   
  }