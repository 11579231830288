.conttak {
    display: grid;
    place-items: center;
    width: 100%;
    min-height: 100dvh;
}

.outerkontak {
    width: 90%;
    height: 90%;
    gap: 5px;
    /* flex-wrap: wrap; */
    align-items: end;
    /* background-color: pink; */
    padding-bottom: 11px;
}

.boxsleft {
    width: 50%;
    /* min-height: 70%; */
    justify-content: center;
    gap: 15px;
    padding: 33px 21px;
    /* align-items: center; */
    flex-direction: column;
    -webkit-box-shadow: 5px 3.5px 7.5px 0.5px #dadbf3;
    -moz-box-shadow: 5px 3.5px 7.5px 0.5px #dadbf3;
    box-shadow: 5px 3.5px 7.5px 0.5px #dadbf3;
    /* gap: 1rem; */
    /* background-color: red; */
}

.boxsleft:hover {
    -webkit-box-shadow: 0.5px 3.5px 5.5px 0.5px #dadbf3;
    -moz-box-shadow: 0.5px 3.5px 5.5px 0.5px #dadbf3;
    box-shadow: 0.5px 3.5px 5.5px 0.5px #dadbf3;
}

.boxsleft h3,
h1,
p {
    margin: 0;
    padding: 0;
}

.boxsleft>p {
    font-size: 16px;
    font-weight: 400;
}

.boxsleft>h1 {
    color: var(--palett1);
    /* font-size: 32px; */
    font-weight: 600;
    font-size: 24px;
}

.boxsleft>h3 {
    color: var(--palett3);
    font-size: 19px;
}

.boxsright {
    width: 50%;
    min-height: 70%;
    align-items: end;
    justify-content: center;
    padding: 1px 11px;
    /* background-color: blue; */
}

.itm22 h4,
p {
    margin: 0;
    padding: 0;
}

.itm22>h4 {
    color: var(--palett3);
    font-size: 19px;
    font-weight: 500;
}

.itm22>p {
    font-size: 14px;
    text-wrap: wrap;
}

.itm1 {
    width: 55px;
    height: 50px;
    -webkit-box-shadow: 0.5px 3.5px 5.5px 0.5px #dadbf3;
    -moz-box-shadow: 0.5px 3.5px 5.5px 0.5px #dadbf3;
    box-shadow: 0.5px 3.5px 5.5px 0.5px #dadbf3;
}

.itm1 svg {
    margin: 0;
}
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
.map-container {
    position: relative;
    /* padding-bottom: 56.25%;  */
    height: 0;
    overflow: hidden;
  }

@media screen and (max-width: 680px) {
    .outerkontak {
        width: 100%;
        min-height: 130dvh;
        flex-direction: column;
        align-items: center;
        padding-top: 5.5rem;
        gap: 0;
    }

    .boxsleft {
        width: 90%;
    }

    .boxsright {
        align-items: start;
    }
}

@media screen and (min-device-width : 681px) and (max-device-width : 1024px) {
    .outerkontak {
        display: flex;
        min-height: 130dvh;
        flex-direction: column;
        align-items: center;
        padding: 5.5rem;
        gap: 5px;
    }

    .boxsleft {
        width: 70%;
    }

    .boxsright {
        align-items: start;
    }

}