.contprice{
    flex-direction: column;
    min-height: 100dvh;
    align-items: center;
    /* gap: 1rem; */
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.438), rgba(0, 0, 0, 0.432)),url("../../images/prime/proknow2.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* color: #fff; */
    
}

/* .contprice p,h1,h2,h3,h4,h5,h6{
    color: #fff;
} */

.pricings {
    gap: 2.5rem;
    /* background-color: red; */
}

.pricingcard{
    display: flex;
    flex-direction: column;
    /* place-items: center; */
    border-radius: 3px;
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: space-around; */
    width: 210px;
    height: 300px;
    /* background-color: pink; */
    box-shadow: 0px 1px 2px gray;
    background: transparent;
    backdrop-filter: blur(1px);
}
.pricingcard:hover{
    backdrop-filter: blur(5px);
    box-shadow: 0px 2px 3px gray;
}
.bungkusprice{
    display: flex;
    text-align: start;
    /* align-items: center; */
    flex-direction: column;
    width: 90%;
    height: 90%;
    /*  */
    /* background-color: red; */
}

.btn-price{
    width: 130px;
    background-color: var(--palett3);
    color: white;
    padding: 9px 15px;
    margin: auto;
}

.firstpricel h4{
    font-size: 26px;
}

.firstpricel p{
    margin: 0;
}

@media (max-width: 610px){
    .pricings{
        flex-direction: column;
    }
}