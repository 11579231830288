.background-video {
    position: relative;
    width: 100%;
    height: 100dvh; 
    overflow: hidden;

  }
  
  .background-video video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  
  .animation-container {
    position: absolute;
    bottom: 20px; 
    left: 50%;
    transform: translateX(-50%);
  }
  .skeleton-loading {
    position: relative;
  }
  
  .shimmer {
    display: grid;
    place-items: center;
    color: gainsboro;
    /* font-size: clamp(19px, (2.5vh), 42px); */
    width: 60%;
    height: 60%;
    border-radius: 10px;
  }
  .bgsimlg{
    background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
    animation: shimmer 1.5s infinite linear;
    background-size: 200% 100%;
  }
  .bgsimdark{
    background: linear-gradient(-45deg, #333 40%, #444 50%, #333 60%);
    animation: shimmer 1.5s infinite linear;
    color: red;
    background-size: 200% 100%;
  }

  @media screen and (max-width: 768px) {
    .shimmer{
      width: 90%;
    }
  }
  
  @keyframes shimmer {
    0% {
      background-position: -100%;
    }
    100% {
      background-position: 200%;
    }
  }
  