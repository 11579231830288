/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
} */
:root {
  --color-text1: #3f4146;
  --color-text2: #ffffff;
  --color-btn: #565656;
  --card1-gradient-color1: #2B3499;
  --card1-gradient-color2: #3e4bdb;
  --card2-gradient-color1: #fad62b;
  --card2-gradient-color2: #fcb30c;
  --card3-gradient-color1: #f89e00;
  --card3-gradient-color2: #f2540f;
  --mainblue: #2B3499;
}
/* body {
  font-family: "Roboto", sans-serif;
  background: linear-gradient(to right, #8e9eab, #eef2f3);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 120px 10px;
} */
.carddttt{
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.card-wrap {
  width: 210px;
  height: 375px;
  border-radius: 10px;
  position: relative;
}
.card-wrap::after {
  
  position: absolute;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: -75px;
  right: 0;
  z-index: 10;
  color: var(--color-text2);
  font-size: 22px;
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.card-wrap:nth-of-type(1) {
  background: linear-gradient(
    to bottom,
    var(--card1-gradient-color1),
    var(--card1-gradient-color2)
  );
  transform: scale(0.9);
}
.card-wrap:nth-of-type(1)::after {
  background: var(--card1-gradient-color1);
  content: "500K";
  color: #fff;
}
.card-wrap:nth-of-type(2){
  background: linear-gradient(
    to bottom,
    var(--card2-gradient-color1),
    var(--card2-gradient-color2)
  );
}
.card-wrap:nth-of-type(2)::after {
  background: var(--card2-gradient-color1);
  content: "800K";
  color: #fff;
}
.card-wrap:nth-of-type(3){
  background: linear-gradient(
    to bottom,
    var(--card3-gradient-color1),
    var(--card3-gradient-color2)
  );
  transform: scale(0.9);
}
.card-wrap:nth-of-type(3)::after {
  background: var(--card3-gradient-color1);
  content: "1.500K";
  color: #fff;
}
.card-header {
  width: 160px;
  height: 105px;
  background: #ffffff;
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 100% 0%, 100% 75%, 50% 100%, 0 75%);
}

.card-header h1 {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 21px;
  color: var(--color-text1);
  margin-top: -10px;
}
.card-content {
  width: 160px;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  /* text-align: center; */
}
.card-content-option {
  color: var(--color-text2);
  font-size: 14px;
  margin-bottom: 10px;
}
.card-content-option.false {
  color: #000;
}
.card-content-option.false::before {
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
  font-size: 20px;
  vertical-align: middle;
}
.card-content-option.true::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
  font-size: 20px;
  /* text-align: start; */
  /* vertical-align: middle; */
}
/* .card-content-option p{
  text-align: start;
} */
.card-footer {
  width: 160px;
  position: absolute;
  bottom: -12%;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.card-footer p {
  font-size: 10px;
  text-align: center;
  margin: 10px 0;
}
.card-footer button {
  border: 1px solid var(--color-btn);
  border-radius: 5px;
  padding: 5px 15px;
  text-transform: uppercase;
  font-size: 12px;
  background: transparent;
}
