.card-team-container {
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* width: 90%; */
    /* justify-content: center; */
    align-items: center;
    /* background-color: red; */
    /* overflow: hidden; */
    /* height: 50vh; */
    /* background-color: #232323; */
}


.myCard {
    background-color: transparent;
    border-radius: 1px;
    /* width: auto; */
    width: 230px;
    height: 274px;
    /* perspective: 1000px; */
}

.title {
    font-size: 1.5em;
    font-weight: 900;
    text-align: center;
    margin: 0;
}

.innerCard {
    position: relative;
    width: 80%;
    height: 100%;
    text-align: center;
    display: grid;
    place-items: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    cursor: pointer;
    box-shadow: 0 1px 2px gray;
}

.myCard:hover .innerCard {
    transform: rotateY(180deg);
}
.frontSide img{
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
}
.backSide img{
    /* max-width: 90%; */
    /* border-radius: 11px; */
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
    /* aspect-ratio: 3/2; */
    /* object-fit: contain; */
}
.frontSide,
.backSide {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: auto;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 1px;
    color: white;
    box-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
    font-weight: 700;
}

.frontSide,
.frontSide::before {
      /* background: linear-gradient(43deg, rgb(65, 88, 208) 0%, rgb(200, 80, 192) 46%, rgb(255, 204, 112) 100%); */
      /* background: linear-gradient(43deg, rgb(65, 88, 208) 0%, var(--palett3) 46%, var(--palett4) 100%); */
}

.backSide,
.backSide::before {
    /* background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%); */
    /* background-image: linear-gradient(160deg, #0093E9 0%, var(--palett2) 100%); */
}

.backSide {
      transform: rotateY(180deg);
}

.frontSide::before,
.backSide::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    width: 110%;
    height: 110%;
    position: absolute;
    z-index: -1;
    border-radius: 1em;
    filter: blur(20px);
    animation: animate 5s linear infinite;
}

@keyframes animate {
    0% {
        opacity: 0.3;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}