.h1testii{
  font-weight: bold;
  font-size: 32px;
}

.h1testii::after {
  content: " Say";
  color: var(--palett2);
  position: relative;
  /* top: 0;
  left: 100%; */
}

.h3testii{
  font-size: 19px;
  font-weight: 600;
  color: var(--palett3);
}

.embla {
  position: relative;
  width: 100%;
  margin: 0 auto;
  cursor: grab;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.embla__slide {
  /* flex: 0 0 auto; */
  flex: 0 0 calc(33.333% - 20px);
  /* width: calc(100% / 3); */
  /* Sesuaikan dengan jumlah item per slide */
  padding: 0 10px;
  box-sizing: border-box;
}

.embla__controls {
  /* position: relative; */
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.embla__prev,
.embla__next {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 23px;
  outline: none;
}

.embla__prev {
  margin-right: 10px;
}

.embla__next {
  margin-left: 10px;
}

.review-card {
  justify-content: space-around;
  flex-direction: column;
  border: 1px solid #ddd;
  /* border-radius: 5px; */
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
  width: 100%;
  height: 250px;
}

.review-card__rating span {
  color: orange;
}

.review-card__name {
  color: var(--palett3);
  font-weight: 600;
  /* margin-bottom: 5px; */
}

.review-card__review {
  font-style: italic;
  /* font-family: "Poppins", 'sans-serif'; */
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: start;
}

/* .slidedotneks{
  display: ;
  bottom: 0;
  
} */

/* Responsiveness */
@media (max-width: 768px) {
  .embla__slide {
    width: calc(100% / 2);
    /* Menyesuaikan dengan jumlah item per slide */
  }

  .review-card {
    width: 310px;
    height: 240px;
  }
}

@media (max-width: 480px) {
  .embla__slide {
    width: 100%;

  }

  .review-card {
    width: 270px;
    height: 250px;
  }
}

@media (min-width: 769px) and (max-width: 1040) {
  .embla__slide {
    width:100%;
  }
  .review-card {
    width: 50%;
    height: 150px;
  }
}

@media (max-width: 2100) {
  .review-card {
    width: 350px;
    height: 250px;
  }
}