
.nav-link {
    position: relative; 
    display: inline-block; 
  }
  
  .nav-link::before,
  .nav-link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: transparent; 
    transition: width 0.5s ease; 
  }
  
  .nav-link:hover::before,
  .nav-link:hover::after {
    width: 100%; 
    /* background-color: #13121233; */
    background-color: var(--palett4);
  }
  
