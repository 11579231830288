.h1proj{
  font-size: 32px;
  font-weight: bold;
  /* position: relative; */
}
.outerprooj{
  /* // #fcf8f8 */
background-color: #fcf8f8;
}
.h1proj::after {
  content: " Project";
  color: var(--palett3);
  position: relative;
  /* top: 0;
  left: 100%; */
}
.h6proj{
  font-size: 16px;
  color: rgb(141, 131, 131);
}

.project-embla {
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  
  .project-embla__viewport {
    overflow: hidden;
    width: 100%;
  }
  
  /* !! custom css */
  .bgcolorb{
    background-color: var(--palett4);
  }
  
  /* .project-embla__slide {
    flex: 0 0 auto;
    padding: 0 10px;
    transition: transform 0.3s ease;
  } */
  
  /* .project-embla__slide--active {
    flex: 0 0 60%;
  } */
  .project-embla__container {
    display: flex;
    justify-content: space-between; /* Menambahkan ruang antara kartu */
  }
  
  .project-embla__slide {
    flex: 0 0 calc(33.333% - 20px); /* Mengatur agar hanya tiga kartu yang ditampilkan */
    padding: 0 10px;
    transition: transform 0.5s ease;
    cursor: grab;
  }
  
  
  .project-card {
    position: relative;
    width: 100%;
    height: 200px; /* Atur tinggi kartu sesuai kebutuhan Anda */
    border-radius: 1px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  
  .project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .project-card__image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  .project-card__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .project-embla__slide:hover .project-card__content {
    opacity: 1;
  }
  
  .project-card__name,
  .project-card__description {
    margin: 0;
    padding: 0;
  }
  
  .project-embla__controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
  }
  
  .project-embla__prev,
  .project-embla__next {
    /* background-color: rgba(255, 255, 255, 0.541); */
    background-color: var(--palett3);
    backdrop-filter:  blur(7px) saturate(189%) contrast(73%);
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 5px;
    outline: none;
  }
  
  @media (max-width: 480px) {
    .project-embla__slide {
      flex: 0 0 100%; /* Mengatur agar setiap slide memenuhi 100% lebar viewport */
      padding: 0 10px; /* Menjaga padding agar konten tidak menempel tepat di pinggir layar */
      transition: transform 0.5s ease;
      cursor: grab;
    }
    .project-card {
      width: 100%; /* Memastikan kartu proyek memenuhi lebar slide sepenuhnya */
      margin: 0 auto; /* Opsional: Tambahkan ini jika Anda ingin kartu berada di tengah slide */
    }
  }
  