.puter{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    background-color: #2B3499;
    padding: 3rem 2.5rem 1rem;
    /* padding: 1rem 25px; */
    /* align-items: center; */
    /* justify-content: center; */
    /* gap: 3rem; */
    /* font-family: pop; */
}

.firstfoot{
    display: flex;
    flex-direction: row;
    gap: 5rem;
    justify-content: center;
    /* justify-content: space-evenly; */
}
.firstfoot p,li{
    /* color: black; */
    font-weight: 300;
    font-size: 16px;
    /* list-style-type: circle; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.firstfoot li:hover{
    color: var(--palett3);
}

.kol1{
    /* background-color: blueviolet; */
    /* width: px; */
    /* display: flex; */
    max-width: 450px;
    color: white;
    /* text-wrap: wrap; */

}
.firstfoot h1{
    font-size: 21px;
    font-weight: 500;
    /* line-height: 1.9; */
}
.firstfoot h5{
    font-size: 19px;
    color: var(--palett3);
}

.kol1 h5{
    font-size: 17px;
    padding: 0;
    margin: 0;
}

.kol2{
    /* display: flex;
    flex-direction: column; */
    /* width: 350px; */
    color: white;
    /* background-color: pink; */
}
.kol3{
    /* width: 150px; */
    color: white;
    /* background-color: red; */
}
.kol4{
    /* width: 150px; */
    display: flex;
    flex-direction: column;
    color: white;
    gap: 1rem;
    /* background-color: green; */
}

.fdetail{
    display: flex;
    gap: 15px;
    /* align-self: flex-start; */
}
.isikol{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.sosiall svg{
    color: var(--palett4);
    /* justify-content: center; */
    /* align-items: center; */
}
.sosiall svg:hover{
    color: var(--palett1);
}
.sosiall{
    border-radius: 9px;
    padding: 0px 3px 3px;
    background-color: var(--palett2);
}

.secfot{
    display: flex;
    flex-direction: row;
    gap: 5rem;
}

.textcopyrig{
    color: white;
}
/* Gaya untuk layar lebih kecil dari 600px (Mobile) */
@media only screen and (max-width: 600px) {
    .firstfoot{
        display: flex;
        flex-direction: column;
    }
    .kol4{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .secfot{
        justify-content: space-between;
    }
    
  
    /* Tambahkan aturan responsif lainnya sesuai kebutuhan untuk tata letak mobile */
  }
  
  /* Gaya untuk layar antara 601px dan 1024px (Tablet) */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    
  
   
  }
  
  /* Gaya untuk layar lebih besar dari 1024px (Desktop) */
  @media only screen and (min-width: 1025px) {
    
  
    /* Tambahkan aturan responsif lainnya sesuai kebutuhan untuk tata letak desktop */
  }