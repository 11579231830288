.outerportoff{
    width: 100%;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
}

.portoppp1{
    height: 100dvh;
    background-image: url("https://www.w3schools.com/howto/img_parallax.jpg");    
}
.portoppp2{
    min-height: 500px;
    background-image: url("https://www.w3schools.com/howto/img_parallax3.jpg");
}

.portoppp3{
    min-height: 500px;
    background-image: url("https://www.w3schools.com/howto/img_parallax.jpg"); 
}
.portoppp4{
    min-height: 500px;
    background-image: url("https://www.w3schools.com/howto/img_parallax3.jpg");
}

.portoppp5{
    min-height: 100dvh;
    background-image: url("https://www.w3schools.com/howto/img_parallax.jpg");
}
.portoppp1, .portoppp2, .portoppp3, .portoppp4, .portoppp5 {
    position: relative;
    opacity: 0.65;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cappor{
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    color: #000;
}

.cappor span.borderr{
    /* background-color: #111; */
    background-color: var(--palett2);
    color: #fff;
    padding: 18px;
    font-size: 25px;
    letter-spacing: 10px;
}