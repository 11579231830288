/* src/components/FAQPage.css */
.mengapaa{
  background-image: linear-gradient(rgba(0, 0, 0, 0.438), rgba(0, 0, 0, 0.432)),url("../../images/prime/proknow3.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 100dvh; */
}
.faq-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
  }
  
  .faq-list {
    margin-top: 20px;
  }
  
  .faq-item {
    /* border: 1px solid #ddd; */
    margin-bottom: 10px;
    border-radius: 11px;
    background-color: var(--palett2);
  }
  
  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 11px;
    color: white;
    background-color: var(--palett3);
  }
  
  .question.open {
    background-color: var(--palett3);
    box-shadow: 0px 20px 100px rgba(0, 0, 0, 0.1); /* Shadow hanya di bawah container */
    /* border-radius: ; */
  }
  
  .answer {
    padding: 10px;
    text-align: start;
    color: white;
    border-radius: 0 0 11px 11px;
    background-color: var(--palett2);
  }
  
  .arrow-icon {
    vertical-align: middle;
    margin-left: 8px; /* Atur jarak antara teks pertanyaan dan ikon panah */
  }
  
  /* setingan bagian mengapa harus kami */
  @media only screen and (max-width: 600px) {
    .whycontainer{
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }

    .kotaks{
      width: 100%;
    }
  }