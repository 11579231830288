/* CSS untuk News.jsx setelah implementasi text editor */

/* Gaya umum */
.news-container {

  background-color: #f5f5f5;
}

.news {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  color: #333;
}

.form-container {
  width: 100%;
  max-width: 600px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

input,
textarea,
.public-DraftEditor-content {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.btn-container {
  text-align: center;
}

.btn {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* Gaya khusus untuk input tag */
.tag {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
}

input[type="text"][placeholder="Tag"] {
  display: inline-block;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Gaya tambahan jika diperlukan */
.news-item {
  /* Gaya untuk item berita dalam tampilan konten berita */
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.news-item img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

/* CSS untuk News.jsx setelah implementasi text editor */

/* Gaya untuk editor */
.public-DraftEditor-content {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  min-height: 200px; /* Atur ketinggian minimum editor */
  box-sizing: border-box;
  overflow-y: auto; /* Tampilkan scrollbar jika konten lebih panjang */
}
/* Gaya untuk editor */
.react-draft-wysiwyg {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.public-DraftEditor-content {
  padding: 10px;
  min-height: 200px; /* Atur ketinggian minimum editor */
  box-sizing: border-box;
}
